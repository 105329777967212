<template>
    <div>
      <h1>관리자 임시 페이지</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AdminTemporary'
  };
  </script>
  
  <style scoped>
  /* 스타일 작성 */
  </style>